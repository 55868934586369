<template>
    <v-container fluid class="px-6">

      <v-card>

        <v-card-title class="px-6 card-header white--text">
          <v-icon class="mr-3 white--text">mdi-format-list-bulleted</v-icon> <h3>List Point of Interest</h3>
        </v-card-title>

        <div style="max-height: calc(100vh - 190px);overflow-y:auto;">
          <v-data-table
          :headers="headers"
          :items="itemsWithIndex"
          class="elevation-2 headerDtSarawak px-6 pt-6"
          id="interesttable"
          >

            <template v-slot:[`item.no`]="props">
                {{ props.item.index }}
            </template>

            <template v-slot:[`item.actions`]="props">
              <v-icon small class="mr-2 green--text" @click="editPointItem(props.item)">mdi-pencil</v-icon>
              <v-icon small text class="red--text" @click="deletePointItem(props.item.ID,props.item.NAME)">mdi-delete</v-icon>
            </template>
      
          </v-data-table>
        </div>

      </v-card>

      <!------ Dialog Edit ------>
      <v-dialog
        v-model="editDialog"
        max-width="700px"
        persistent
      >
        <v-card>

          <v-card-title class="card-header white--text">
            <v-icon class="white--text mr-2">mdi-text-box-edit</v-icon><h3>Edit Point of Interest :  </h3> <h3 class="mx-2">{{selectedPoint}}</h3>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                >
                  <v-text-field
                    v-model="editedPoint.name"
                    label="Name"
                    outlined dense hide-details
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                >
                  <v-text-field
                    v-model="editedPoint.description"
                    label="Description"
                    outlined dense hide-details
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="editedPoint.longitude"
                    label="Longitude"
                    outlined dense hide-details
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <v-text-field
                    v-model="editedPoint.latitude"
                    label="Latitude"
                    outlined dense hide-details
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-file-input
                  :placeholder="editedPoint.picturePath.split('_').pop()"
                    @change="uploadPictureInterest(uploadPic)"
                    v-model="uploadPic" outlined dense
                    label="Point of Interest Picture"
                  ></v-file-input>
                  <a v-if="editedPoint.picturePath != ''" :href="editedPoint.picturePath" target="_blank">
                    <v-icon small class="ml-3 primary--text">mdi-message-image-outline</v-icon>
                    <span class="ml-2 primary--text" style="font-size:0.9em;">See Data Image</span>
                  </a>
                  <a v-if="editedPoint.picturePath == ''" style="cursor:default;">
                    <v-icon small class="ml-3 black--text" style="cursor:default;">mdi-image-off</v-icon>
                    <span class="ml-2 black--text" style="font-size:0.9em;cursor:default;">No Data Image</span>
                  </a> 
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions class="card-footer">
            <v-spacer></v-spacer>
            <v-btn
              @click="close"
              color="error"
            >
              CANCEL
            </v-btn>
            <v-btn
              @click="postEditPoint"
              color="button" class="white--text px-4 mx-3"
            >
              SAVE
            </v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>

      <!------ Dialog Delete ------>
      <v-dialog v-model="dialogDelete" persistent max-width="500px">
        <v-card>
          
          <v-card-title class="error white--text">
            <v-icon class="white--text mr-2">mdi-text-box-edit</v-icon><h3>Delete Point of Interest </h3>
          </v-card-title>

          <v-card-text class="pa-5 d-flex justify-center">
            <h3>
              Are you sure you want to delete this Point of Interest? <br> <center><v-icon class="mr-2 mb-1">mdi-map-marker</v-icon>{{selectedPoint}}</center>
            </h3>
          </v-card-text>

          <v-card-actions class="card-footer">
            <v-spacer></v-spacer>
            <v-btn class="error" @click="close">CANCEL</v-btn>
            <v-btn color="button" class="white--text px-4 mx-3" @click="postDeletePoint">DELETE</v-btn>
            
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <!----- Snackbar Message ---->
      <v-snackbar
        v-model="snackbarMsg"
        :color="snackbarColor"
      >
        {{ message }}

        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="snackbarMsg = false"
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    selectedPoint: '',
    message : '',
    snackbarColor: '',
    snackbarMsg: false,
    editDialog: false,
    dialogDelete: false,
    delSelected: '',
    headers: [
        { text: 'NO.', align: 'start', value:'no' },
        { text: 'Point of Interest Name', value:'NAME' },
        { text: 'DESCRIPTION', value:'DESCRIPTION' },
        { text: 'ACTIONS', value:'actions', sortable: false }
    ],

    pointData:[],
    uploadPic: null,
    editedPoint: {
        id: '',
        name: '',
        description: '',
        longitude: '',
        latitude: '',
        picturePath: ''
    },
    editedIndex: -1,

  }),

  methods: {
    
    getAllPoint(){
      this.pointData = [];
      axios.get(this.globalUrl+`bakaj/interest`, {
          headers: {
              Authorization: 'Bearer ' + this.$store.getters.user.token,
          },
      })
      .then((response) => {
          this.pointData = response.data
      })
      .catch((error) => {
          console.log(error)
      })
    },

    postEditPoint() {
      const payload = this.editedPoint;

      axios.put(this.globalUrl+`bakaj/editinterest`, payload, {
        headers: {
            Authorization: 'Bearer ' + this.$store.getters.user.token,
        },
      })
      .then((response) => {
          this.getAllPoint();
          this.close();
          this.snackbarMsg = true;
          this.snackbarColor = "success";
          this.message = "List has been updated";
      })
      .catch((error) => {
          console.log(error);
          this.snackbarMsg = true;
          this.snackbarColor = "error";
          this.message = "Error. Please try again";
      })
    },

    postDeletePoint() { 
        axios.get(this.globalUrl+'bakaj/delinterest/'+this.delSelected, {
          headers: {
              Authorization: 'Bearer ' + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.close();
          this.getAllPoint();
          this.snackbarMsg = true;
          this.snackbarColor = "button";
          this.message = "List has been updated";
        })
        .catch((error) => {
          console.log(error);
          this.snackbarMsg = true;
          this.snackbarColor = "error";
          this.message = "Error. Please try again";
        })
    },

    editPointItem(item) {
        // this.editedIndex = this.pointData.indexOf(item)
        // this.editedPoint = Object.assign({}, item)
        this.editDialog = true;

        this.selectedPoint = item.NAME;
        this.editedPoint.id = item.ID;
        this.editedPoint.name = item.NAME;
        this.editedPoint.description = item.DESCRIPTION;
        this.editedPoint.latitude = item.LATITUDE;
        this.editedPoint.longitude = item.LONGITUDE;
    },

    deletePointItem(id,name) {
      //this.delSelected = val
      this.selectedPoint = name;
      this.delSelected = id;
      this.dialogDelete = true;
    },

    close () {
      this.editDialog = false
      this.dialogDelete = false
    },

    uploadPictureInterest(data){
      let formData = new FormData();

        formData.append("files", data);

        axios.post(this.globalUrl+'bakaj/upload', formData, {
            headers: {
                Authorization: "Bearer " + this.$store.getters.user.token,
            },
        })
        .then((response) => {
            console.log(response);
            // this.stationData = response.data.filepath
            this.pointData.picturePath = response.data.filepath;
            this.editedPoint.picturePath = response.data.filepath;
        })
        .catch((error) => {
            console.log(error);
        });
    }

  },

  computed: {
    itemsWithIndex() {
       return this.pointData.map(
           (pointData, index) => ({
           ...pointData,
           index: index + 1
         }))
    }
  },

  mounted() {
      this.getAllPoint()
  }
}
</script>

<style lang="scss" scoped>

</style>
